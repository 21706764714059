import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_Sign-In',
    resetPassword: 'B2C_1_ResetPassword',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://ffxloginsit.b2clogin.com/ffxloginsit.onmicrosoft.com/B2C_1A_SIGN-IN',
    },
  },
  authorityDomain: 'ffxloginsit.b2clogin.com',
};

const msalConfig: Configuration = {
  auth: {
    clientId: '5b465f58-2570-4193-842d-c018f68958a1', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

const loginRequest = {
  scopes: [msalConfig.auth.clientId],
};

export const environment = {
  ffxFlowApiUrl: 'https://ffx-flowapi.sit.flowforma.net',
  ffxFileApiUrl: 'https://ffx-flowapi.sit.flowforma.net/File',
  auth: {
    msalConfig: msalConfig,
    loginRequest: loginRequest,
  },
};
